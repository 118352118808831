export const lineData = [
    {
      id: "japan",
      data: [
        {
          x: "plane",
          y: 102
        },
        {
          x: "helicopter",
          y: 83
        },
        {
          x: "boat",
          y: 177
        },
        {
          x: "train",
          y: 130
        },
        {
          x: "subway",
          y: 275
        },
        {
          x: "bus",
          y: 243
        },
        {
          x: "car",
          y: 80
        },
        {
          x: "moto",
          y: 60
        },
        {
          x: "bicycle",
          y: 241
        },
        {
          x: "horse",
          y: 211
        },
        {
          x: "skateboard",
          y: 189
        },
        {
          x: "others",
          y: 111
        }
      ]
    }
  ];