import React from "react";
import LiveMeasurements from "./LiveMeasurements";

const Header = () => {
  return (
    <>
      <nav align="center" mr={5} className="navbar">
        <div className="navlogo">Pythlab</div>
        <ul>
          <li className="navelement"><a href='/'>Overview</a></li>
          <li className="navelement"><a href='/cables'>Cables</a></li>
        </ul>
      </nav>
      <LiveMeasurements/>
    </>
  );
};

export default Header;
