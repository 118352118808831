import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import {
    Table,
    TableContainer,
    Tbody,
    Tr,
    Td,
    Th,
    Thead,
    Heading,
    Card,
    CardHeader,
    CardBody
} from "@chakra-ui/react";

import Experiment from "./Experiment";
const BatteryContext = React.createContext({
    inventoryitems: [], fetchBattery: () => {}
  })

export default function Battery() {
    const [searchParams] = useSearchParams();
    const [inventoryitem_id, setInventoryId] = useState(null)
    const [battery, setBattery] = useState({})
    const [batterytype, setBatterytype] = useState({})

    const fetchBattery = async () => {
      const response = await fetch("https://pythlab.krijnders.net/api/v1/batteries/" + inventoryitem_id)
      const battery = await response.json()
      setBattery(battery)
    }
    const fetchBatterytype = async () => {
      const response = await fetch("https://pythlab.krijnders.net/api/v1/batterytypes/" + battery.batterytype)
      const batterytype = await response.json()
      setBatterytype(batterytype)
    }
    useEffect(() => {
      if (inventoryitem_id) {
        fetchBattery()
      };
    }, [inventoryitem_id])

    useEffect(() => {
      fetchBatterytype()
    }, [battery])

    useEffect(() => {
      setInventoryId(parseInt(searchParams.get("id")))
      for (const [key, value] of searchParams.entries()){
        console.log(key, value);
      };
      console.log(searchParams.entries())
      console.log("iid: ", searchParams.get("id"))
    }, [searchParams])
    
    return (
      <BatteryContext.Provider value={{battery, fetchBattery, batterytype, fetchBatterytype}}>

        <Heading size="lg">{batterytype.size} - {battery.marking}</Heading>

        <Card>
        <CardHeader>{batterytype.brand} - {batterytype.model}</CardHeader>
        <CardBody>
        {batterytype.description}
        <TableContainer>
            <Table>
                <Thead><Th>Parameter</Th><Th>Value</Th><Th>Unit</Th></Thead>
                <Tbody>
                    <Tr><Td>Nominal capacity</Td><Td isNumeric>{batterytype.nominal_capacity}</Td><Td>Ah</Td></Tr>
                    <Tr><Td>Nominal voltage</Td><Td isNumeric>{batterytype.nominal_voltage}</Td><Td>V</Td></Tr>
                    <Tr><Td>Nominal charge current</Td><Td isNumeric>{batterytype.charge_current_nominal}</Td><Td>A</Td></Tr>
                    <Tr><Td>Maximum charge current</Td><Td isNumeric>{batterytype.charge_current_max}</Td><Td>A</Td></Tr>
                    <Tr><Td>Maximal charge voltage</Td><Td isNumeric>{batterytype.charge_voltage_max}</Td><Td>V</Td></Tr>
                    <Tr><Td>Nominal discharge current</Td><Td isNumeric>{batterytype.discharge_current_nominal}</Td><Td>A</Td></Tr>
                    <Tr><Td>Maximum discharge current</Td><Td isNumeric>{batterytype.discharge_current_max}</Td><Td>A</Td></Tr>
                    <Tr><Td>Minimum discharge voltage</Td><Td isNumeric>{batterytype.discharge_voltage_min}</Td><Td>V</Td></Tr>
                </Tbody>
            </Table>
        </TableContainer></CardBody></Card>
        {battery !== undefined &&
          <Experiment dut={battery.id} />
        }
        </BatteryContext.Provider>
    )
}
