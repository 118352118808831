import React from "react";
import { Routes, Route } from "react-router-dom"
import { Flex, Box } from "@chakra-ui/react";

// import Header from "./components/Header";
// import InventoryItems from "./components/Items";
// import Battery from "./components/Battery";

// import { useState } from "react";
import { CableOverview } from "./components/Cable";
import InventoryItems from "./components/Items";
import Battery from "./components/Battery";
function App() {
  return (
      <>
        <Flex>
          <Box className="leftbar" minWidth="200px"></Box>
          <Box className="content" flex="1">
            <Routes>
              <Route path="/" element={<InventoryItems/>}/>
              <Route path="/cables" element={<CableOverview/>}/>
              <Route path="/batterys" element={<Battery/>}/>
            </Routes>
          </Box>
        </Flex>
      </>
  )
}
//   const [state, setState] = useState('inventoryitems');
//   const [id, setId] = useState('');
//   const {isOpen, onOpen, onClose} = useDisclosure();
//   
//   const handleClick = (event) => {
//     setId(event.target.value);
//     setState(event.target.textContent);
//     console.log(event);
//   }
// 
//   let content;
//   if (state === 'inventoryitems') {
//     content = <InventoryItems inventoryitem_id={id} handleClick={handleClick} isOpen={isOpen} onClose={onClose}/>
//   } else if (state === 'battery') {
//     content = <Battery inventoryitem_id={id} handleClick={handleClick}/>
//   } else {
//     content = <InventoryItems/>
//   }
//   
// 
//   return (
//     <ChakraProvider>
//       <Header />
//       {content}
// 
//     </ChakraProvider>
//   )
// }
// 
export default App;
