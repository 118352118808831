import { Line } from "@nivo/line";
import { lineData } from "./data";
import React, { useState, useEffect } from "react";
import { InfluxDB } from "@influxdata/influxdb-client";


const token =  "4T-O-8LNfZBYBANTKsNMrkrLtlbOSAfUG1tf7YpXBN90lsLLmNSdOU8-IR1X34T3CHeo9g6aQJfXSWHMppRuzg==";
const org = "krijnders.net";
const bucket = "pythlab";
const url = "http://loki.krijnders.net:8086/";

let query = `from(bucket: "${bucket}")
  |> range(start: 2024-01-03T10:00:00Z, stop: 2024-01-03T15:40:00Z)
  |> filter(fn: (r) => r["_measurement"] == "raw")
  |> filter(fn: (r) => r["_field"] == "measured_value")
  |> group(columns: ["device"])
  |> aggregateWindow(every: 5m, fn: mean, createEmpty: false)
  |> yield(name: "mean")`;


export default function InfluxMeasurementGraph({ params /* see data tab */ }) {
    console.log("EQRWT");
    console.log(lineData);

    const [data, setData] = useState([]);

  useEffect(() => {
    let res = [];
    const influxQuery = async () => {
      //create InfluxDB client
      const queryApi = await new InfluxDB({ url, token }).getQueryApi(org);
      //make query
      await queryApi.queryRows(query, {
        next(row, tableMeta) {

          const o = tableMeta.toObject(row);
          console.log(o)
         //push rows from query into an array object
          res.push(o);
        },
        complete() {

          let finalData = []

          //variable is used to track if the current ID already has a key
          var exists = false

          //nested for loops aren't ideal, this could be optimized but gets the job done
          for(let i = 0; i < res.length; i++) {
            for(let j =0; j < finalData.length; j++) {
              //check if the sensor ID is already in the array, if true we want to add the current data point to the array
              if(res[i]['device'] === finalData[j]['id']) {
                exists = true

                let point = {}
                point["x"] = res[i]["_time"];
                point["y"] = res[i]["_value"];
                finalData[j]["data"].push(point)
              }

            }
             //if the ID does not exist, create the key and append first data point to array
              if(!exists) {
                let d = {}
              d["id"] = res[i]["device"];
              d['data'] = []
              let point = {}
              point["x"] = res[i]["_time"];
              point["y"] = res[i]["_value"];
              d['data'].push(point)
              finalData.push(d)
              }
              //need to set this back to false
              exists = false

          }

          setData(finalData);

        },
        error(error) {
          console.log("query failed- ", error);
        }
      });

    };

    influxQuery();
  }, []);


  return (<Line
  width={800}
  height={600}
    colors={{ scheme: "dark2" }}
    data={data}
    margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
    xScale={{ type: "point" }}
    yScale={{
      type: "linear",
      min: 0,
      max: "auto",
      stacked: true,
      reverse: false
    }}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      orient: "bottom",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 90,
      legend: "transportation",
      legendOffset: 36,
      legendPosition: "middle"
    }}
    axisLeft={{
      orient: "left",
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: "count",
      legendOffset: -40,
      legendPosition: "middle"
    }}
    pointSize={10}
    pointColor={{ theme: "background" }}
    pointBorderWidth={2}
    pointBorderColor="black"
    pointLabelYOffset={-12}
    useMesh={true}
    legends={[
      {
        anchor: "bottom-right",
        direction: "column",
        justify: false,
        translateX: 100,
        translateY: 0,
        itemsSpacing: 0,
        itemDirection: "left-to-right",
        itemWidth: 80,
        itemHeight: 20,
        itemOpacity: 0.75,
        symbolSize: 12,
        symbolShape: "circle",
        symbolBorderColor: "rgba(0, 0, 0, .5)",
        effects: [
          {
            on: "hover",
            style: {
              itemBackground: "rgba(0, 0, 0, .03)",
              itemOpacity: 1
            }
          }
        ]
      }
    ]}
  />
)
};
