import mqtt from "mqtt";
import { useState, useEffect } from "react";

function reviver(key, value) {
  if (value === "***NaN***") {
    return NaN;
  }
  if (value === "***Infinity***") {
    return Infinity;
  }
  if (value === "***-Infinity***") {
    return -Infinity;
  }
  return value;
}

export default function LiveMeasurements() {
  const topic_map = {
    "pythlab/Philips/PM 2522/1/measurement": 82,
    "pythlab/Philips/PM 2534/1/measurement": 83,
  };
  const [payload, setPayload] = useState({});
  const [trend, setTrend] = useState("trend-na");
  const [connectStatus, setConnectStatus] = useState(false);
  const [client, setClient] = useState(null);

  const mqttConnect = (host, mqttOption) => {
    setConnectStatus("Connecting");
    setClient(mqtt.connect(host, mqttOption));
  };

  useEffect(() => {
    mqttConnect("wss://home.krijnders.net:8885", {
      username: "ha",
      password: "qwer1234",
      rejectUnauthorized: false,
    });
  }, []);

  useEffect(() => {
    if (client) {
      client.on("connect", () => {
        setConnectStatus("Connected");
        client.subscribe("pythlab/+/+/+/measurement", (err) => {
          if (err) {
            console.log("Failed to subscribe to PM 2534: ", err);
          }
        });
      });
      client.on("error", (err) => {
        console.error("Connection error: ", err);
        client.end();
      });
      client.on("reconnect", () => {
        setConnectStatus("Reconnecting");
      });
      client.on("message", (topic, message) => {
        let lastValue = null;
        if (topic in payload) {
          console.log("Setting last value");
          lastValue = payload[topic].message.value;
        }
        const msg_text = message.toString();
        var tmp = msg_text.replace(/(NaN|-?Infinity)/g, '"***$1***"');

        var payload_msg = { [topic]: { message: JSON.parse(tmp, reviver) } };
        let trend_ = "trending-stable";
        if (lastValue) {
          console.log(lastValue, payload_msg[topic].message.value);
          if (lastValue < payload_msg[topic].message.value) trend_ = "trending";
          else if (lastValue > payload_msg[topic].message.value)
            trend_ = "trending-down";
        }
        setTrend(trend_);
        //console.log({...payload, ...payload_msg})
        setPayload((p) => ({
          topics: { ...p.topics, [topic]: { ...payload_msg[topic] } },
        }));
      });
    }
  }, [client]);

  console.log(payload);
  return (
    <div className="live-measurements">
      <div
        className={`status-blob ${
          connectStatus === "Connected" ? "status-green" : "status-red"
        }`}
      ></div>
      {"topics" in payload &&
        Object.keys(payload.topics).map((topic) => (
          <div className="live-measurement" key={topic}>
            <span className="noWrap">
              {payload.topics[topic].message.value.toFixed(
                payload.topics[topic].message.decimals
              )}{" "}
              {payload.topics[topic].message.unit}
              <span className="subscript">
                {payload.topics[topic].message.subscript}
              </span>
            </span>
            <div className={`measurement-trend gg-${trend}`}></div>
            <div className="measurement-device-name">{topic.split("/")[2]}</div>
          </div>
        ))}
    </div>
  );
}
