import React, { useEffect, useState } from "react";
import { CableForm } from "./Cable";

const InventoryItemsContext = React.createContext({
    inventoryitems: [], fetchInventoryItems: () => {}
  })

export default function InventoryItems({inventoryitem_id, handleClick, isOpen, onClose}) {
    const [inventoryitems, setInventoryItems] = useState([])

    const fetchInventoryItems = async () => {
      const response = await fetch("https://pythlab.krijnders.net/api/v1/inventoryitems/")
      const inventoryitems = await response.json()
      setInventoryItems(inventoryitems)
    }
    useEffect(() => {
      fetchInventoryItems()
    }, [])
  

    return (
      <InventoryItemsContext.Provider value={{inventoryitems, fetchInventoryItems}}>
        <table spacing='24px'>
          <thead><tr><th>Number</th><th>Code</th><th>Brand</th><th>Model</th><th>Type</th><th></th></tr></thead>
          <tbody>
          {inventoryitems.map((item) => (
          <tr key={item.id}>
            <td>{item.number}</td>
            <td>{item.code}</td>
            <td>{item.brand}</td>
            <td>{item.model}</td>
            <td><a className="linkbutton" href={`/${item.type}s?id=${item.id}`}>{item.type}</a></td>
            </tr>
           ))}
           </tbody>
           </table>
{/*         </TableContainer> */}
      </InventoryItemsContext.Provider>
    )
}
