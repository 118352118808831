import {useEffect, useState} from "react"
import {
    Button,
    ButtonGroup,
    Input,
    ModalFooter,
    Modal,
    ModalOverlay,
    ModalContent,
    HStack,
    Stack,
    Tooltip,
    Box,
    Accordion,
    AccordionButton,
    AccordionItem,
    AccordionPanel,
    useDisclosure,
    Editable,
    EditablePreview,
    EditableInput,
} from "@chakra-ui/react";
import { BsFillTrash3Fill, BsPlusLg} from "react-icons/bs";
import { useSearchParams } from 'react-router-dom';
import QRCode from "react-qr-code";

export function CableOverview() {
    const [cables, setCables] = useState([])
    const [searchParams] = useSearchParams();
    const [index, setIndex] = useState([]);
    
    
    useEffect(() => {
      const fetchCables = async () => {
        const response = await fetch("https://pythlab.krijnders.net/api/v1/cables/")
        const cables = await response.json()
        setCables(cables)
        let tempIndex = []
        for (const [key, value] of searchParams.entries()){
          console.log(key, value)
          tempIndex.push([cables.indexOf(cables.filter((cable) => `${cable[key]}` === value)[0])])
        }
        console.log("tempIndex: ",tempIndex.flat())
        setIndex(tempIndex.flat())
        //setIndex([cables.indexOf(cables.filter((cable) => cable.nfc_tag === queryNFCTAG)[0])])
      }
    
      fetchCables();
    }, [searchParams])

    if (cables.length > 0) {
      return (<CableAccordion cables={cables} dIndex={index}/>)
    } else {
      return (
      <h1>loading</h1>)
    }
  }

function CableAccordion({cables, dIndex}) {
    const {isOpen, onOpen, onClose} = useDisclosure()
    const [editable, setEditable] = useState(true)
    function handleEdit(id) {
      setEditable((prevState => !prevState))
    }

    function handleChange(id, name, value) {
      console.log(id, name, value)
    }

    function handlePrint(id) {
      const cable = cables.filter((cable) => cable.id === id)[0]
      const body = 
          {text: `Inv: ${cable.number} ${cable.code}`,
          scale: 55,
          }
      console.log(body, cable, id)
      fetch("http://gpibberry.krijnders.net:8000/print", {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body
        )
      })
        .then((response) => {
          if (response.status === 200) {
            return response.json()
          } else {
            console.log(response)
          }
        })
    }
    function saveChange(id, name, value) {
      console.log(id, name, value)
    
    fetch("https://pythlab.krijnders.net/api/v1/cables/" + id, {
      method: 'PATCH',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({[name]: value}),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      }
      )
    }
 

    function handleDelete(id) {
      fetch("https://pythlab.krijnders.net/api/v1/cables/" + id, {
        method: 'DELETE',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      }
    )}

    function EditableItem({cable, name, width='200px', alignment='left', className=''}) {
      return (
        <div className={`editable-field`}>
{/*           <input class="editable-input" value={cable[name]} type="text" placeholder={cable[name]} readOnly/> */}
            {cable[name]}
        </div>
/*         <Editable 
          defaultValue={cable[name]} 
          isDisabled={editable} 
          onChange={(value) => handleChange(cable.id, name, value)}
          onSubmit={(value) => saveChange(cable.id, name, value)}
          width={width}
          placeholder="-"
          textAlign={alignment}
          className={className}
          >
          <EditablePreview />
          <EditableInput />
        </Editable> */
      )
    }
    //setIndex([0])
    //index = [0]
    //console.log(editable)
    // <Accordion {...(index.length > 0 ? {defaultIndex: {index}}: {})}>
    let di = [0, 1]
    console.log(cables.length, dIndex, di)
    return (
      <>
        <section className="accordion">
            {cables.map((cable) => {
                return (
                  <div className="tab" key={cable.id}>
                    <form>
                    <input type="checkbox" name="accordion-1" id={cable.id}/>
                      <label for={cable.id} className="tab__label">{cable.number} - {cable.cabletype}</label>
                    <div className="tab__content">
                      <div className="cable-panel">
                        <div className="panel-title">Properties</div>
                        <div className="property"><div className="label">Connector 1</div><EditableItem cable={cable} name="connector_1" width="100px" className="value"/></div>
                        <div className="property"><div className="label">Connector 2</div><EditableItem cable={cable} name="connector_2" width="100px" className="value"/></div>
                        <div className="property"><div className="label">Brand</div><EditableItem cable={cable} name="brand" className="value"/></div>
                        <div className="property"><div className="label">Model</div><EditableItem cable={cable} name="model" className="value"/></div>           
                        <div className="property"><div className="label">Owner</div><EditableItem cable={cable} name="owner"  className="value"/></div>   
                        <div className="property"><div className="label">Location</div><EditableItem cable={cable} name="location"  className="value"/></div>         
                        <div className="property"><div className="label">Resistance</div><EditableItem cable={cable} name="resistance" width="40px" alignment="right" className="value"/><div className="unit">Ω</div></div>
                        <div className="property"><div className="label">Length</div><EditableItem cable={cable} name="length" width="40px" alignment="right" className="value"/><div className="unit">m</div></div>
                        {cable.properties && Object.keys(cable.properties).map((key) => {
                          return (<div className="property"><div className="label">{key}</div><div className="value right">{cable.properties[key]}</div></div>) 
                        })}
                        <div className="panel-title">Identifiers</div>
                        <div className="identifier"><div className="label">NFC Tag</div><EditableItem cable={cable} name="nfc_tag" className="value"/></div>             
                        <div className="identifier"><div className="label">Number</div><EditableItem cable={cable} name="number" width="40px" className="value"/></div> 
                        <div className="identifier"><div className="label">Code</div><EditableItem cable={cable} name="code" width="40px" className="value"/></div> 
                        <div className="identifier"><div className="lablel">Url</div><QRCode size={75} value={`https://pythlab.krijnders.net/cables?id=${cable.id}`}/></div>
                        <div className="identifier"><div className="lablel">Json data</div><QRCode size={75} value={`{"id": ${cable.id}, "type": "cable"}`}/></div>
                        <ButtonGroup variant='outline' spacing='6'>
                          <Tooltip label={editable ? "Start editing" : "Stop editing"}>
                            <Button onClick={() => handleEdit(cable.id)}><i className="gg-pen"></i></Button>
                          </Tooltip>
                          <Button onClick={() => handleDelete(cable.id)}><BsFillTrash3Fill /></Button>
                          <Button onClick={() => handlePrint(cable.id)}><i className="gg-printer"></i></Button>
                        </ButtonGroup>
                      </div>
                    </div>
                  </form>
                </div>
                )
            }
            )}
        </section>
        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay/>
          <ModalContent>
            <CableForm onClose={onClose}/>
          </ModalContent>
        </Modal>
        <Button onClick={onOpen} ><BsPlusLg/></Button>
      </>
    )
}

export function CableForm({onClose}) {
  const [newCable, setNewCable] = useState({"type": "cable"})
  const [state, setState] = useState("input")
  const [error, setError] = useState([])

  const handleChange = (event) => {
    const {name, value} = event.target
    setNewCable((prevNewCable) => {
      return {
      ...prevNewCable,
      [name]: value
    }
    })
  }
  function handleSave() {
    console.log("Save")
    setState("sending")
    fetch("https://pythlab.krijnders.net/api/v1/cables/", {
      method: "POST",
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newCable),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 422) { // Unprocessable input
          const data = response.json()
          data.then((data) => {
            console.log("data:")
            console.log(data)
            setError(data.detail)
          })
            throw new Error()
        } else {
          console.log(response.json())
          throw new Error(response.statusText);
        }
      
      })
      .then(() => {
        setState('done');
        onClose()
      })
      .catch((err) => {
        console.log(err.toString());
        setState('error');
      });

  }
  console.log(newCable)
  console.log("Error state: ")
  console.log(error)
  let errorKeys
  if (error) {
    errorKeys = error.map((d) => d.loc[1])
  }
  console.log(errorKeys)
  return(
    <>
    <Stack>
    <Input
      isInvalid={errorKeys.includes("nfc_tag") ? true : false}
      value={newCable.nfc_tag && newCable.nfc_tag}
      placeholder="nfc_tag"
      onChange={handleChange}
      size="sm"
      name="nfc_tag"
      />
    <Input
      isInvalid={errorKeys.includes("number") ? true : false}
      value={newCable.number && newCable.number}
      placeholder="number"
      onChange={handleChange}
      size="sm"
      name="number"
      />
    <Input
      isInvalid={errorKeys.includes("code") ? true : false}
      value={newCable.code && newCable.code}
      placeholder="code"
      onChange={handleChange}
      size="sm"
      name="code"
      />
    <Input 
      isInvalid={errorKeys.includes("brand") ? true : false}
      value={newCable.brand && newCable.brand}
      placeholder="brand"
      onChange={handleChange}
      size="sm"
      name="brand"
      />
    <Input
      isInvalid={errorKeys.includes("model") ? true : false}
      value={newCable.model && newCable.model}
      placeholder="model"
      onChange={handleChange}
      size="sm"
      name="model"
      />
      {errorKeys.includes("model") && <b>Required field</b>}
    <Input 
      isInvalid={errorKeys.includes("owner") ? true : false}
      value={newCable.owner && newCable.owner}
      placeholder="owner"
      onChange={handleChange}
      size="sm"
      name="owner"
      />
    <Input
      isInvalid={errorKeys.includes("location") ? true : false}
      value={newCable.location && newCable.location}
      placeholder="location"
      onChange={handleChange}
      size="sm"
      name="location"
      />
    <Input
      isInvalid={errorKeys.includes("cabletype") ? true : false}
      value={newCable.cabletype && newCable.cabletype}
      placeholder="cabletype"
      onChange={handleChange}
      size="sm"
      name="cabletype"
      />
    <Input
      isInvalid={errorKeys.includes("connector_1") ? true : false}
      value={newCable.connector_1 && newCable.connector_1}
      placeholder="connector_1"
      onChange={handleChange}
      size="sm"
      name="connector_1"
      />
    <Input
      isInvalid={errorKeys.includes("connector_2") ? true : false}
      value={newCable.connector_2 && newCable.connector_2}
      placeholder="connector_2"
      onChange={handleChange}
      size="sm"
      name="connector_2"
      />
    <Input
      isInvalid={errorKeys.includes("resistance") ? true : false}
      value={newCable.resistance && newCable.resistance}
      placeholder="resistance"
      onChange={handleChange}
      size="sm"
      name="resistance"
      />

      </Stack>
      <ModalFooter>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} colorScheme="blue" isLoading={state === "sending"}>Save</Button>

      </ModalFooter>
      </>
  )
}
