import React, { useEffect, useState } from "react";
import {
    Table,
    TableContainer,
    Tbody,
    Tr,
    Td,
    Th,
    Thead,
} from "@chakra-ui/react";

import Measurements from "./Measurements";
import InfluxMeasurementGraph from "./InfluxMeasurementGraph"

const ExperimentContext = React.createContext({
    experiments: [], fetchExperiments: () => []
  })

export default function Experiment({dut}) {
    const [experiments, setExperiments] = useState([])
    const [selectedExperiment, setSelectedExperiment] = useState()
    const [graphparams, setGraphParams] = useState({})

    const fetchExperiments = async () => {
      const response = await fetch("https://pythlab.krijnders.net/api/v1/experiments/search/" + dut + "?field=dut")
      if (response.status === 200) {
        const experiments_= await response.json()
        console.log("experiments state: " + experiments_);
        setExperiments(experiments_)
        let experiment = experiments_.slice(-1)[0]
        setSelectedExperiment(experiment)
        console.log("Selected experiment: " + selectedExperiment);
      let params = {"start": experiment.start_datetime, "stop" : experiment.stop_datetime, "devices": [2, 3]};
      setGraphParams(params);
      };
    };
    
    useEffect(() => {
        fetchExperiments()
    }, [dut]);

    const onExperimentRowClick = (event) => {
      console.log(event);
      setSelectedExperiment(event);
      console.log("Selected experiment: " + selectedExperiment.id);
    };

   let m;
   if (typeof selectedExperiment === "undefined") {
    m = <br/>;
   } else {
    console.log("SelectedExperiment is not undefined:");
    console.log(selectedExperiment);
      m= <Measurements experiment={selectedExperiment} />

   };


   // console.log(experiments);
    return (
      <ExperimentContext.Provider value={{experiments, fetchExperiments, selectedExperiment}}>
        <InfluxMeasurementGraph params={graphparams}/>
        <TableContainer>
          <Table>
            <Thead><Th>Start</Th><Th>Stop</Th><Th>Type</Th></Thead>
            <Tbody>
              {experiments.map((experiment) => (

              <Tr onClick={() => onExperimentRowClick(experiment)} key={experiment.id}>
                <Td>
                  {experiment.start_datetime}
                </Td><Td>{experiment.stop_datetime}</Td><Td>{experiment.type}</Td></Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        {m}
      </ExperimentContext.Provider>
    )
    };
