import React, { useEffect, useState } from "react";
import {
    Table,
    TableContainer,
    Tbody,
    Tr,
    Td,
    Th,
    Thead
} from "@chakra-ui/react";


const MeasurementsContext = React.createContext({
    measurementss: [], fetchMeasurementss: () => []
  })

export default function Measurements({experiment}) {
    const [measurements, setMeasurements] = useState([])

    const fetchMeasurements = async () => {
      console.log(experiment)
      const response = await fetch("https://pythlab.krijnders.net/api/v1/measurements/search/" + experiment.id + "?field=experiment")
      const measurement = await response.json()
      setMeasurements(measurement)
      console.log("Measurements: " + measurement)

    }
    
    useEffect(() => {
       fetchMeasurements()
    }, [experiment]);

    return (
      <MeasurementsContext.Provider value={{measurements, fetchMeasurements}}>
        <TableContainer>
          <Table>
            <Thead><Th>Start</Th><Th>Stop</Th><Th>Device</Th></Thead>
            <Tbody>
              {measurements.map((measurement) => (

              <Tr key={measurement.id}>
                <Td>
                  {measurement.start_datetime}
                </Td><Td>{measurement.stop_datetime}</Td><Td>{measurement.device}</Td></Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </MeasurementsContext.Provider>
    )
    };
